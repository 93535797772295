<template>
  <div class="bg global-blue">
    <v-container v-if="isLoading == true">
      <v-row>
        <v-col>
          <Loader></Loader>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col>
              <v-card
                :color="$blue"
                class="my-12 mx-2 hidden-lg-and-up"
                flat
                dark
              >
                <p class="title-o-md">Raimo Pyyny</p>
                <p class="title-w-md">Klusterijohtaja</p>
                <p class="title-o-md">raimo.pyyny@lapinamk.fi</p>
                <p class="title-o-md">+358 40 555 8065</p>
              </v-card>
              <v-card
                :color="$blue"
                class="my-12 mx-2 hidden-md-and-down"
                flat
                dark
              >
                <p class="title-o">Raimo Pyyny</p>
                <p class="title-w">Klusterijohtaja</p>
                <p class="title-o">raimo.pyyny@lapinamk.fi</p>
                <p class="title-o">+358 40 555 8065</p>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-search"
            :label="
              locale == 'fi' ? 'Hae yhteystiedoista' : 'Search from contacts'
            "
            outlined
            hide-details
            dark
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            class="contact global-blue"
            :headers="headers"
            dark
            item-key="contact"
            :items="contactItems"
            :search="search"
            @click:row="rowclick"
            :items-per-page="contactItems.length"
            hide-default-footer
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.bg {
  height: 100%;
  padding-bottom: 300px;
}
h1 {
  color: #f7941e;
  font-size: 22px;
  font-weight: 500;
}
h2 {
  font-size: 20px;
}
.title-o {
  font-size: 28px;
  color: #f7941e;
  font-weight: 500;
  margin-top: -20px;
}
.title-o-md {
  font-size: 18px;
  color: #f7941e;
  font-weight: 500;
  margin-top: -20px;
}
.title-w {
  font-size: 28px;
  color: white;
  font-weight: 500;
  margin-top: -20px;
}
.title-w-md {
  font-size: 18px;
  color: white;
  font-weight: 500;
  margin-top: -20px;
}
.title-env {
  font-size: 20px;
}
.emp {
  font-size: 16px;
}
.inp {
  color: white;
}
.contact {
  background: var(--v-primary-base) !important;
  cursor: pointer;
}
</style>

<script>
import EmployeesService from "../../services/contacts";
import Loader from "../../components/Loader";
import EnvironmentsService from "../../services/environments";

export default {
  name: "Contacts",
  components: { Loader },
  data() {
    return {
      isLoading: true,
      employees: [],
      environments: [],
      contactItems: [],
      selected: {},
      search: "",
    };
  },
  methods: {
    storage(key, data) {
      if (!data) {
        const cache = window.localStorage.getItem(key);
        if (cache) return cache;
        return;
      }
      window.localStorage.setItem(key, data);
    },
    rowclick(data) {
      this.$router.push({ name: "environmentView", params: { id: data.id } });
    },
    formatPhone(a) {
      if (!a) return "";

      let b = a.match(/\d+/g).join("");
      if (b[0] == "0") b = b.replace("0", "358");
      let c = b.split("");
      for (let i in c) if (i % 3 == 2) c[i] = c[i] + " ";
      return `+${c.join("").trim()}`;
    },
    fetchEmployees() {
      EmployeesService.getInstance()
        .findAllContacts()
        .then((employees) => {
          this.employees = [...employees];

          this.contactItems = employees.reduce((acc, cur) => {
            let obj = {
              firstname: cur.g.find((e) => e.tag == "firstname"),
              lastname: cur.g.find((e) => e.tag == "lastname"),
              email: cur.g.find((e) => e.tag == "email"),
              phone: cur.g.find((e) => e.tag == "phone"),
              incharge: cur.g.find((e) => e.tag == "incharge"),
            };

            const q = this.environments.find(
              (e) => e.fi._id == cur.questionnaire
            );

            let obj2 = {
              name: `${obj.firstname != null ? obj.firstname.answer.fi : ""} ${
                obj.lastname != null ? obj.lastname.answer.fi : ""
              }`,
              email: obj.email != null ? obj.email.answer.fi : "",
              phone: obj.phone != null ? obj.phone.answer.fi : "",
              incharge: obj.incharge != null ? obj.incharge.answer.fi : false,
              q: q[this.locale].name,
              id: q[this.locale]._id,
            };

            obj2.phone = this.formatPhone(obj2.phone);

            if (obj2.incharge === false) {
              return acc;
            }

            return (acc = [...acc, ...[obj2]]);
          }, []);

          this.isLoading = false;
          this.storage("contacts_portal", JSON.stringify(this.contactItems));
        })
        .catch(() => {
          // console.log(err);
        });
    },
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
    headers() {
      return [
        {
          text: this.locale == "fi" ? "Nimi" : "name",
          align: "left",
          value: "name",
        },
        { text: this.locale == "fi" ? "Sähköposti" : "Email", value: "email" },
        { text: this.locale == "fi" ? "Puhelin" : "Phone", value: "phone" },
        {
          text:
            this.locale == "fi"
              ? "Kehittämisympäristö"
              : "Development environment",
          value: "q",
        },
      ];
    },
  },
  created() {
    const contactsCache = this.storage("contacts_portal");
    if (contactsCache) {
      this.contactItems = JSON.parse(contactsCache);
      this.isLoading = false;
    }

    EnvironmentsService.getInstance()
      .findAll()
      .then((e) => {
        this.environments = [...e];
        this.fetchEmployees();
      })
      .catch(() => {
        // console.log(err);
      });
  },
};
</script>
